.health-table {
  .table;
  .table-condensed;
  .table-bordered;

  > tbody > tr > th {
    background: #68AEBE;
    color: #fff;
    font-weight: bold;
    border-color: #1B647E;
  }

  > tbody > tr > td {
    border-color: #1B647E;
  }

  .country-row {
    &:nth-child(odd) {
      background: #e8f2f5;

      th {
        background: #e8f2f5;
      }
    }
  }

  th.country-name {
    //color: #EF843B;
    font-weight: bold;
    //background: #DDE9ED;
    color: #1b647e;
    background: #fff;
  }

  .group-heading-row-first {
    th {
      border-color: #1B647E;
    }
  }

  .group-heading-row-second {
    th {
      background: #DDE9ED;
      color: #1B647E;
      border-color: #1B647E;
    }
  }

  td .label {
    white-space: normal;
    display: block;
  }

  td .label.label-default {
    background: #999;
  }

  td .label.label-info {
    background: #467C96;
  }

  td .label + .label {
    margin-top: 2px;
  }

}

.todo {
  padding: 20px;
  background: red;
  color: white;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 20px;
  margin-top: 20px;
}

#health-accordion.panel-group {
  .panel {
    border-radius: 0;
    border-color: @blue-light;
  }

  .panel-info > .panel-heading {
    background: @blue-light;
    border-color: @blue-light;
    color: #fff;
    border-radius: 0;

    .panel-title {
      font-weight: bold;
    }

    .fa {
      width: 40px;
    }
  }

  .panel-group .panel + .panel {
    margin-top: 1px;
  }
}

.health-graph-footer-item {
  font-size: 13px;
  padding: 20px 20px;
}
.health-graph-footer-item + .health-graph-footer-item {
  border-left: 1px solid #ccc
}

.factoid-data-title small {
  display: block;
}

.health-row-numeric {
  td:nth-child(1) {
    text-align: right;
  }
  td:nth-child(2) {
    text-align: right;
  }
}

.answer-img {
  width: 100%;
  display: block;
}

health-question-formatted-answer, .fact-value {
  .label {
    font-size: 14px;
    margin-bottom: 2px;
    &.label-info {
      background: #2d8da2;
    }
  }
}

#chartjs-tooltip {
  background: rgba(0,0,0,0.8);
  color: #fff;

  &:after {
    content: "";
    display: block;
    border: 5px solid transparent;
    border-color: transparent;
    border-bottom-color: rgba(0,0,0,0.8);
    position: absolute;
    top: -10px;
    left: 10px;
    height: 10px;
    width: 10px;
  }
}