@import (reference) "variables";

.col-bdr, .col-bdl, .col-bdt, .col-bdb {
  position: relative;
}
.col-bdr:after,
.col-bdl:before,
.col-bdt:before,
.col-bdb:after {
  content:"";
  position: absolute;
  line-height: 1px;
  border:0 solid @gray-light;
}

.col-bdr:after,
.col-bdl:before  {
  height: 100%;
}

.col-bdb:after,
.col-bdt:before  {
  left: 0;
  width: 100%;
}

.col-bdr:after {
  right: 0;
  top: 0;
  border-right-width: 1px;
}

.col-bdl:before {
  left: 0;
  border-left-width: 1px;
}

.col-bdb:after {
  bottom: 0;
  border-bottom-width: 1px;
}

.col-bdt:before {
  top: 0;
  border-top-width: 1px;
}

/* notifications */
#notifications-wrapper { position: absolute; z-index: 101; width: 100%; }
body.ltr #notifications-wrapper { left: 0; }
body.rtl #notifications-wrapper { right: 0; }

#notifications-wrapper.fixed { position: fixed; top: 0; }
.notification {
  *:last-child {
    margin-bottom: 0;
  }
}
#notifications { text-align: center; }

#notifications .notification {
  display: inline-block;
  padding: 5px 10px;
  border-width: 0 1px 1px;
  color: #fff !important;
  font-weight: 200;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.35);

}

#notifications .notification.message { background: #5286d9; }
#notifications .notification.notice { background: #5286d9; }

#notifications .notification.error { background: #da5a47; }



.p-0 {
  padding: 0 !important;
}
.pt-1 {
  padding-top: 15px;
}

.pt-2 {
  padding-top: 30px;
}

.pt-3 {
  padding-top: 45px;
}

.pb-0 {
  padding-bottom: 0 !important;
}
.pb-1 {
  padding-bottom: 15px;
}

.pb-2 {
  padding-bottom: 30px;
}

.pb-3 {
  padding-bottom: 45px;
}
.pb-4 {
  padding-bottom: 60px;
}

.p-1 {
  padding: 15px;
}

.p-2 { padding: 30px; }

.mb-lastchild-0 > *:last-child { margin-bottom: 0; }

.px-sm {
  padding-left: @padding-small-vertical;
  padding-right: @padding-small-vertical;
}

.px-1 {
  padding-left: 15px;
  padding-right: 15px;
}
.px-2 {
  padding-left: 30px;
  padding-right: 30px;
}

.pl-0 { padding-left: 0 !important; }
.pl-1 { padding-left: 15px !important; }
.pl-2 { padding-left: 30px !important; }

.pl-sm-2 { 
  @media (min-width: @screen-sm) {
      padding-left: 30px !important;
    }
}
.pr-0 { padding-right: 0 !important; }
.pr-1 { padding-right: 15px !important; }
.pr-2 { padding-right: 30px !important; }

.pr-sm-2 { 
  @media (min-width: @screen-sm) {
    padding-right: 30px !important;
  }
}
.py-xs {
  padding-top: @padding-xs-horizontal !important;
  padding-bottom: @padding-xs-horizontal !important;
}

.py-sm {
  padding-top: @padding-small-vertical;
  padding-bottom: @padding-small-vertical;
}
.py-1 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.pt-sm { padding-top: @padding-small-vertical; }
.pb-sm { padding-bottom: @padding-small-vertical; }


.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.mt-auto { margin-top: auto;}
.mt-sm { margin-top: @padding-small-horizontal !important; }
.mt-1 { margin-top: 15px;}

.mx-n1 { margin-left: -15px !important; margin-right: -15px !important; }
.mx-auto { margin-left: auto !important; margin-right: auto !important; }
.mx-0 { margin-left: 0 !important; margin-right: 0 !important; }

.ml-0 { margin-left: 0 !important; }
.mr-0 { margin-right: 0 !important; }

.mr-auto { margin-right: auto; }
.ml-auto { margin-left: auto; }
.mr-sm { margin-right: @padding-small-horizontal; }
.ml-sm { margin-left: @padding-small-horizontal; }

.mb-auto { margin-bottom: auto; }
.mb-sm { margin-bottom: @padding-small-vertical; }
.mb-0 { margin-bottom: 0; }
.mb-1 { margin-bottom: 15px; }
.mb-2 { margin-bottom: 30px; }

.mb-0i { margin-bottom: 0 !important; }

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.bottom-auto { bottom: auto; }
.bottom-2 { bottom: 30px; }
.bottom-n2 { bottom: -30px; }

.top-auto { top:auto; }
.top-0 { top: 0;}
.top-2 { top: 30px;}

.left-auto { left: auto; }
.left-n1 { left: -15px; }
.right-auto { right: auto;}
.right-0 { right: 0; }
.right-1 { right: 15px; }
.right-n1 { right: -15px; }

.right-sm-n2 {
    @media (min-width: @screen-sm) {
    right: -30px;
  }
}

.leading-none { line-height: 1; }
.leading-tight { line-height: 1.25; }
.leading-base { line-height: 1.5; }
.leading-relaxed { line-height: 1.65; }
.leading-loose { line-height: 2; }

.font-weight-lighter { font-weight: 200;}
.font-weight-light { font-weight: 300;}
.font-weight-regular { font-weight: 400;}
.font-weight-bold { font-weight: 700;}

.alpha-50 { opacity: 0.5; }
.alpha-75 { opacity: 0.75; }

.rounded { border-radius: @border-radius-base; }
.rounded-pill { border-radius: 999px !important; }

.rounded-left-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-right-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-bottom-left { border-bottom-left-radius: @border-radius-base !important; }
.rounded-bottom-left-pill { border-bottom-left-radius: 800px !important; }

// border
.border { border: 1px solid @gray-light; }
.border-bottom { border-bottom: 1px solid @gray-light; }

.border-primary {border-color: @brand-primary !important; }
.border-blue-light {border-color: @blue-light !important; }
.border-2 { border-width: 2px !important; }
.border-0 { border-width: 0 !important; }

// shadow
.shadow { box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.15); }
.shadow-sm { box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.075); }
.shadow-none { box-shadow: none; }
.shadow-hover:not(:hover) { box-shadow: none; }
.shadow-highlight-hover:hover { .shadow; }

.cursor-pointer {
  cursor: pointer;
}

@media (min-width: @screen-sm) {
  .h2-sm {
    font-size: @font-size-h2 !important;
  }
  .h3-sm {
    font-size: @font-size-h3 !important;
  }
  .h4-sm {
    font-size: @font-size-h4 !important;
  }
}

.alpha-50 {
  opacity: 50%;
}

.bottom-0 {
  bottom: 0;
}
.text-decoration-none {
  text-decoration: none !important;
}

.col {
  flex: 1 0 0%;
}

.bg-transparent {
	background-color: transparent !important;
}