@import "./bower/bootstrap/less/bootstrap";
@import "./bower/font-awesome/less/font-awesome";
@import "fonts";
@import (reference) "variables";
@import "utilities";

  /* Better Font Rendering */
body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  height: 100%;
}

.bg-transparent {
  background-color: transparent;
}
.bg-gray {
  background-color: #DCDCDC;
}

.bg-dark {
  background-color: #2D3638;
}

.bg-navbar-default {
  background-color:@navbar-default-bg;
}

.bg-light {
  background-color: #efefef;
}

.bg-green-light {
  background-color: @green-light;
}


.bg-blue {
  background-color: @blue-light;
}

.bg-blue-light {
  background-color: @blue-light;
}

.bg-white {
  background-color: white;
}

.text-inherit {
  color: inherit !important;
}

.text-primary-light {
  color: #006484;
}
.bg-primary {
  background-color: #006484;
}

.text-blue-light {
  color: @blue-light;
}

.text-green-dark {
  color: @green-dark;
}
.text-green-darker {
  color: @green-darker;
}

.text-white {
  color: #fff !important;
}

.text-orange {
  color: @orange;
}


.b-text-blue-light {
  b, strong { .text-blue-light; }
}

.page-stats {
  position: absolute;
  top: 0;
  right: 0;
  background-color: @blue-light;
  color: #fffceb;
  font-size: @font-size-small;
}


@media (max-width: @screen-sm) {
  .article-image.align-right,
  .article-image.align-left {
    padding-left:0;
    padding-right:0;
  }
}
.article-image.align-center {
  text-align: center;
  .caption {
    text-align: left;
  }
  .thumbnail {
    display: inline-block;
  }
}

.article-image.align-left {
  padding-left: 0;
}
.article-image.align-right {
  padding-right: 0;
}

.align-right {
  float: right;
}

.align-left {
  float: left;
}

.clear-both {
  clear: both;
}
.clear-left {
  clear: left;
}
.clear-right {
  clear: right;
}



@media (min-width: @screen-sm) and (max-width: @screen-md) {
  .clear-sm {
    clear: left;
  }
}

@media (min-width: @screen-md) {
  .clear-md {
    clear: left;
  }
}

.caption {
  font-size: @font-size-small;
  min-height: @font-size-small;
  box-sizing: content-box;

  p {
    margin-bottom: 0;
  }

  .article-image-attribution {
    float: right;
  }
}

.pull-quote {
  font-size: @font-size-large;
  border-top: 3px solid @text-color;
  border-bottom: 3px solid @text-color;
  padding: @panel-body-padding;
  margin-bottom: @font-size-base;
  text-align: center;
}

.lead {
  font-weight: 200;
}

.lead.bg-primary {
  strong {
    color: @blue-light;
  }
}

.page-heading {
  //margin-bottom: @font-size-h1;
  background-color: @brand-primary;

  .page-title {
    font-weight: 200;
    color: @blue-light;
  }
}




.page-title {
  margin-top: @line-height-computed;
  margin-bottom: @line-height-computed;
}


.page-title-label {
  color: #fff;
  display: inline-block;
  padding-left: @grid-gutter-width / 2;
  margin-left: @grid-gutter-width / 2;
  border-left: 2px solid @blue-light;
}

.section-heading {
  color: @brand-primary-text;
  border-bottom: 1px solid;
  padding-bottom: @line-height-computed / 4;
  margin-bottom: @line-height-computed;
  clear: both;
}

.heading-readmore,
.media-readmore {
  .link {
    font-size: @font-size-base * 0.875;
    vertical-align: middle;
    color: @blue-light;
    text-transform: uppercase;
    display: inline-block;
    box-sizing: border-box;

    &:hover {
      color: darken(@blue-light, 15%);
    }

    &:after {
      .fa-icon();
      content: @fa-var-angle-right;
      padding-left: 5px;
    }
  }
}




.article {
  .article-title {
    &:extend(.page-title);
    margin-top: 0;
    color: @brand-primary;
    font-weight: 300;

  }

  .article-byline {
    position: relative;
    margin-bottom: @font-size-h2;
  }
}


.article-meta {
  .date {
    text-transform: uppercase;
    color: @green-dark;
  }

  .byline { display: block; font-style: italic; }

  .theme { display: block;}
}
.article-heading {
  .article-meta {
    .clearfix();
    margin-top: -1 * (@font-size-h1 / 4);
    margin-bottom: @font-size-h1 /2;

    a {
      .meta > .link()
    }
  }

  small {
    color: @blue-light;
  }
}


.meta {
  .link {
    color: @blue-light;
    &:hover, &:focus {
      text-decoration: none;
      color: darken(@blue-light, 25%);
    }
  }
  a {
    .meta > .link();
  }
}


.article.has-sidebar {
  @main: 9;
  @side: 3;

  //background-color: #fff;
  .make-sm-column(@main);

  + .sidebar {
    .make-sm-column(@side);
  }


  @media (min-width: @screen-sm) {
    &:not(.article-left) {

      left: percentage(@side/12);
      padding-right: 0;
      + .sidebar {
        right: percentage(@main/12);
        padding-left: 0;
        padding-right: 0;
      }

    }
  }
}


@media (max-width: @screen-sm) {
   .article.has-sidebar + .sidebar {
    padding-left: 0;
    padding-right: 0;
  }
}

.article.article-bg-white {
  position: relative;
  background-color: #fff;

  @media (min-width: @screen-sm) {



    &.article-left:after {
      left: auto;
      right: 0;
    }

  }

  &:not(.has-sidebar){
    .article-heading,
    .article-body {


      @media(min-width: @screen-md) {
        width: percentage(8/12);
      }
    }
  }
}


h1, h2, h3, h4 {
  color: @blue-dark;
}

 .btn-blue-light {
    
    &.btn-outline {
      color: @blue-light;
      border: 1px solid;
      &:hover {
         color: darken(@blue-light, 15%);
         border-color: @blue-dark;
         text-decoration: none;
       }
    }
}

.article-body {
  .clearfix();

  > h1, > h2, > h3, > h4 {
    &:first-child:not(.section-heading) {
      margin-top: 0;
      margin-bottom: @font-size-h1 / 2;
    }

    font-weight: 300;
  }

  .media-body a:not([class]),
  p a:not([class]),
  a.external-link:not(.media),
  a.btn-link,
  > a:not([class]) {
    color: @blue-light;
    border-bottom: 1px solid;
    &:hover {
      color: darken(@blue-light, 15%);
      border-color: @blue-dark;
      text-decoration: none;
    }
  }

 

  a.external-link.media {
    &:hover {
      color: darken(@blue-light, 15%);
      border-color: @blue-dark;
      text-decoration: none;

    }
  }

  a.btn-link {
    border: 1px solid;
  }

}

.article-video {
  position: relative;
  padding-bottom: 56.25%;
  //padding-top: 60px;
  //overflow: hidden;
  margin-bottom: 20px;
}

.article-video iframe,
.article-video object,
.article-video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.media-contact {
  font-size: @font-size-base;
  color: #fff;
  float: right;
  text-align: right;
  a {
    color: inherit;
    display: block;
    margin-top: 5px;
    border-bottom: 1px solid;
    &, &:hover, &:focus {
      text-decoration: none;
    }
    &:hover, &:focus {
      color: @green-light;
    }
  }
}

.sidebar {
  @media (min-width: @screen-sm) {
    &:not(.text-align-left ) .nav {
      text-align: right;
    }
  }
  padding-bottom: @line-height-computed;

  .section-heading {
    text-transform: uppercase;
  }
}

a.media {
  display: block;
  .transition(box-shadow transform 0.1s ease);
}

a.media:hover,
a.media:focus,
a.media:active {
  text-decoration: none;
  &.panel {
    .box-shadow(0 1px 1px rgba(0,0,0,.1));
  }
}


path {
  .transition(fill 0.25s ease-in);
}

path.active {
  fill: @blue-dark;
}

// Media
.media {
  .media-right,
  .media > .pull-right {
    padding-left: @panel-body-padding;
  }

  .media-left,
  .media > .pull-left {
    padding-right: @panel-body-padding;
  }

  .media-left, .media-right {
    &[class*="image-"] {
      width: 40%;
    }
  }


  .media-none {
    .media-object{
      .img-responsive();
      .center-block();
    }
  }

  .media-summary {
    p:last-child {
      margin-bottom: @padding-base-vertical;
    }
  }
  .media-image {
    img { &:extend(.center-block); }
  }

  .media-sub-heading {
    font-weight: 700;
    margin-top: 0;
  }

  &.media-responsive {
    .media-right, .media-left {
      @media (max-width: @screen-xs) {
        .media-break();
      }
    }
  }

  &.media-responsive-sm {
    .media-right, .media-left {

      .map {
        width: 350px;
      }
      @media (max-width: @screen-sm) {
        .media-break();
      }
    }
  }

  .media-title {
    font-weight: 300;
    font-size: @font-size-large;
    color: @brand-primary-text;
    line-height:1.2;
    .media-meta {
      font-weight: 300;
      margin-top: 5px;
      margin-bottom: 0;
      color: lighten(@brand-primary-text, 5%);
    }
  }

}


.media-meta {
  font-size: 0.80em;
  line-height: 1.1;
  span {
    text-transform: uppercase;
    padding-top: @padding-small-vertical/2;
    padding-bottom: @padding-small-vertical/2;
    display: inline-block;
    position: relative;
    padding-right: 5px;
    &:before {
      .fa-icon();
      padding-right: 5px;
      color: @blue-dark;
    }
  }

  margin-bottom: @padding-small-vertical;

  color: darken(@blue-light, 10%);

  .venue {
    display: block;
    &:before {
      content: @fa-var-map-marker;
    }
  }

  .size {
    display: block;
    margin-top: @padding-base-vertical;
    color: @green-light;
  }

  .theme {
    display: block;
    color: @green-light;
    line-height: 1.2;
  }

  .category {
    &:before {
      content: @fa-var-tag;
    }

    a {
      color: @green-light;
    }
  }

  .date {

    &:before {
      content: @fa-var-calendar-o;
    }
  }

}

.page-heading .breadcrumb {
  margin-top:  -(@line-height-computed);
  background-color: transparent;
  padding-top: 8px;
  color: #e5e5e5;

  li+li:before {
    .fa-icon();
    content: @fa-var-angle-right;
  }
   a {
    color: darken(#6bb6c7, 5%);
    &:hover {
      color: darken(#6bb6c7, 15%);
    }
   }
}

[download] .download {
  position: absolute;
  bottom: 5px;
  left: 5px;
  color: @green-dark;
  background-color: #fff;
  padding: 5px 15px;
  z-index: 1;
}


.downloads-list-group {
  clear: both;
}

.label {
  padding-top: 0.3em;
}
.media-readmore {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  .label {
    margin-top: 5px;
    font-weight: 400;
    text-transform: uppercase;
  }
}

.media-image {
  position: relative;
  z-index: 1;
  overflow: hidden;
  .media-object {
    width: 100%;
    height: auto;
  }

  .icon,
  .media-object {
    position: absolute;


    + .icon {
      font-size: 3em;
      .opacity(0.35);
      .transition(opacity 0.25s ease);
      color: @blue-light;
      .fa-stack {
        .transition(transform 0.1s ease-in);
      }

      &:hover {
        .opacity(0.95);
        .fa-stack { .translate(0, 3px)}
      }
    }
  }

  .icon {
    font-size: 4em;
    display: flex;
    align-items: center;
    background-color: lighten(@blue-light, 25%);
    .square(100%);

    > [class*="fa-file"],
    .fa-stack {
      margin: 0 auto;
    }

    > [class*="fa-file"].fa {

      &:before {
        font-weight: 300;
      }
    }
  }
}

.media-break {
  display: block;
  float: none;
  padding-right: 0;
  padding-left: 0;
  padding-bottom: @panel-body-padding;

  svg,
  .media-object {
    width: 100%;
    height: auto;
  }
  
  &[class*="image-"] {
    width: 100%;
  }
}


.media.panel {
  border-color: @panel-default-border;
  margin-bottom: @grid-gutter-width;
  position: relative;


  .media-readmore {
    padding: @padding-small-vertical @panel-body-padding;
    text-align: right;

    &:before {
      content: '';
      position: absolute;
      top:0;
      border-top: 1px solid @panel-default-border;
    }
  }

}


.media.vertical {
  .media-body {
    padding: @panel-body-padding @panel-body-padding;
  }

  .media-readmore:before {
    width: ~"calc(100% - 30px)";
    left: 15px;
  }
}

.media:not(.vertical) {

  .media-body {
    position: relative;
    padding-top: @panel-body-padding;
    padding-bottom: @panel-body-padding;
  }

  .media-body:not(.left):not(.right){
    padding-right: @panel-body-padding;
    padding-left: @panel-body-padding;
  }

  .media-body.left {
    padding-right: @panel-body-padding;
  }

  .media-body.right {
    padding-left: @panel-body-padding;
  }

  .media-readmore:before {
    width: ~"calc(100% - 15px)";
    left: 0;
  }

  .media-title {
    margin-bottom: 0;
    margin-top: 0;
  }
}


.media.has-readmore .media-body.none,
.media.vertical:not(a) .media-body.none {
  padding-bottom: (@panel-body-padding * 2) + 8;
  .media-summary {
    margin-bottom: @panel-body-padding;
  }
}


.media.publications {
  .media-image {
    padding-bottom: percentage(21/15);
  }
}

.media,
.media.events,
.media.blog,
.media.news,
.media.search {
  .media-image {
    padding-bottom: 75%;
  }
}



.media.search {
  .media-left .media-image {
    border-right: 1px solid lighten(@blue-light, 25%);
  }

  .media-right .media-image {
    border-left: 1px solid lighten(@blue-light, 25%);
  }
}

.references {
   margin-top: @font-size-h1;
  padding-top: 1em;
  border-top:  1px solid lighten(@blue-light, 25%);
  font-size: @font-size-small + 2;
  line-height: 1.25;
}

.promo-link {
  display: block;
  &:hover, &:focus, &:active {
    text-decoration: none;
  }

  .media-meta {
    font-size: 14px;
    color: white;
  }

  .promo-title {
    color: white;

    @media (max-width: 557px) {
      font-size:  20px;
    }
  }

  .media.promo {
    margin-bottom: @font-size-h2;
  }
}

.promo-banner {
  &:not(.slick-slide):not(:first-child) {
    display: none;
  }
}

.media.work {

  [class*="image-"] {
    width: 100px;
    margin-left: auto;
    margin-right: auto;
  }

  .media-image {
    padding-bottom: 75%;
  }

  .media-title {
    font-size: 20px;
  }

  .media-body {
    text-align: center;
  }

  @media (max-width: @screen-xs-max) {
    .media-summary { display: none; }
  }
}

a.media.staff {
  &:not(:hover) {
    .media-image img {
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
      .transition(filter 0.5s ease-in);
    }
  }

  .media-image:before {
    .fa-icon();
    content: @fa-var-info-circle;
    color: @brand-primary;
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 2;
    font-size: 1.25em;
  }

}
.media.staff {

  .media-image {
    padding-bottom: 100%;

  }

  .meta {
    line-height: 1;
    font-size: 14px;
    font-weight: 500;
  }

  .media-body {
    padding: 7.5px;
  }

  .summary {
    margin-top: @panel-body-padding;
  }
}

.label-lang {
  padding: 2px 3px;
  border-radius: 2px;
  background-color: @brand-info;
  color: white;
  font-size: @font-size-small;
  text-transform: uppercase;
  line-height: 1;
}

.media-image .label-lang {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index:1;
}

.media.document {
  margin: 15px 0 15px;
  position: relative;
  + nav {
        margin-top: @grid-gutter-width;
  }

  border: 1px solid @panel-default-border;

  a {
    &, &:hover, &:active, &:focus {
      text-decoration: none;

    }
  }

  .media-right,
  .media-left {
    background-color: @panel-default-heading-bg;
    text-align: center;
    min-width: 90px;
    padding-top: 5px;
    padding-bottom:5px;
    .fa {
      font-size: 1.25em;
    }

  }

  .size {
    display: block;
    font-size: 0.75em;
    color: @gray;
    white-space: nowrap;
    margin-top: -5px;
  }

  .document-language {
    .label-lang();
    float: right;
  }


  .media-body {
    padding: @panel-body-padding/2;
  }

  .media-left {
    padding-left: @panel-body-padding;
    border-right: 1px solid @panel-default-border;
  }
  .media-right {
    padding-right: @panel-body-padding;
    border-left: 1px solid @panel-default-border;
  }

  .media-meta {
    font-size: 0.9em;
    margin-bottom: 0;
    font-weight: 300;
    text-transform: uppercase;
  }

  .media-title {
    line-height: 1.1;
    margin-bottom: 5px;

    .fa {
      position: absolute;
      left: 10px;
      top: 10px;
    }
  }

  .media-meta, .media-title {
    padding-left: 20px;
  }
}

.media-summary-border-bg {
  position: relative;
  padding-top: @panel-body-padding;
  padding-bottom: @panel-body-padding;
  margin-top: @panel-body-padding;
  margin-bottom: @grid-gutter-width;
  &:after, &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    #gradient > .horizontal(#fff, #afb0af);
  }
  &:before {
    top: 0;
  }
  &:after {
    bottom: 0;
  }
}

.panel {
  > .panel.media {
    border: 1px solid @gray-light;
    padding: @panel-body-padding;
    border-radius: @border-radius-small;
    box-shadow: none;
    margin:  @panel-body-padding;

    .media-body {
      padding: 0;
      *:last-child {
        margin-bottom: 0;
      }
    }
  }
  .media {
    margin-top: 0px;

    .media-object {
      padding: 15px;
    }

    p:last-child {
      margin-bottom: 0px;
    }
  }
}

.list-table {
    list-style: none;
    padding: 0;
    margin: 0;
    display: table;
    border-collapse: collapse;
    width: 100%;
  li {
    display: table-cell;
    + li img {
      padding-left: 2px;
    }

    a {
      display: block;
    }
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}


.footer-media-col {
  width: 25%;
  text-align: right;
  @media (max-width: @screen-sm){
    display: block;
    padding: @panel-body-padding 0 !important;
    width: 100%;
    text-align: center;
  }
}


.oh-google-map-canvas {
  max-width: 100%;
  margin-bottom: @font-size-h1;
}

.mask {
  top: 0;
  left: 0;
  position: absolute;
  .square(100%);
}

.edit-this-page {
  font-size: 14px !important;
  background: #fff;
  display: inline-block;
  padding: 4px;
  height: 22px;
  width: 22px;
  line-height: 14px;
  color: @gray;

  &:hover {
    background: @gray;
    color: #fff;
  }
}

.block-link-edit {
  position: absolute;
  bottom: 0;
  z-index: 100;
  margin-bottom: 5px;
}

// Footer
#footer {
  color: #fff;
}

.subscribe-footer {
  background: @brand-primary;
  padding-top: @panel-body-padding;
  padding-bottom: @panel-body-padding;
  
  .section-heading {
    color: white;
  }

  .control-label {
    font-weight: 400;
    @media (max-width: @grid-float-breakpoint) {
      display: block;
    }
  }
  .form-control {
    background-color: transparent;
    box-shadow: none;
    color: #FFF;
    font-weight: 400;
    border-radius: 3px;

  }

  .form-group {
    margin-bottom: 12px;
    vertical-align: top;
    @media (max-width: @grid-float-breakpoint) {
      text-align: left;
    }
  }

  .parsley-errors-list {
    font-size: @font-size-small;
  }

  .btn {
    border-radius: 3px;
  }



  .dropdown {
    display: inline-block;

    .btn-dropdown-toggle {
      min-width: 125px;
    }
    
    .dropdown-menu {
      background-color: lighten(@brand-primary, 5%);
      border-radius: 3px;
      padding: 0;
      font-size: 14px;
    }
  }
}
  .list-option {
    display: block;
    padding: 3px 10px;
    cursor: pointer;
    clear: both;
    line-height: 1.5;
    &:hover {
      background-color: @brand-primary;
      color: #fff;
    }
  }

.btn-dropdown-toggle {
  border-color: #ccc;
  text-align: left;
  width: 100%;
  background-color: transparent;
  &:hover, &:focus, &:active {
    background-color: transparent;
    outline: none;
    border-color: #66afe9;
  }

  padding-right: 25px;
  &:after {
    .fa-icon();
    content: @fa-var-chevron-down;
    position: absolute;
    top: ~"calc(50% - 6px)";
    right: 10px;
    font-size: 0.75em;
  }
}
.top-footer {
  background-color: darken(@blue-dark, 10%);
  padding-top: @panel-body-padding;
  padding-bottom: @panel-body-padding;
}


.parsley-errors-list {
  margin: 4px 0 0;
  padding: 0;
  list-style: none;
  color: @brand-danger;
  font-size: @font-size-small;
  li {
    text-align: left;
  }
}
.footer-social-links {
  a {
    color: @blue-light;

    &:hover {
      color: darken(@blue-light, 15%);
    }
  }
}
.copyright-link, #footerNav {
  a { color: #fff; }
}

.site-footer, #footerNav {
  a {
    border-bottom: 1px solid transparent;
    text-decoration: none;
    &:hover {
      text-decoration: none;
      color: @green-dark;
      border-color: @green-dark;
    }
  }
}
.site-footer {
  background: @brand-primary;
  padding-top: @panel-body-padding;
  padding-bottom: @panel-body-padding;

  .media-body {
    padding: 0 !important;
  }

}

#footerNav {
  margin: 0;
}

.footer-social-links {
  margin-bottom: 0;
  font-size: 1.125em;

  @media (min-width: @screen-lg) {
    font-size: 1.25em;
  }
}

.site-copyright {
  padding-top: 15px;
  .media-left {
    padding:0;
    height: 60px;
  }
  @media (min-width: @screen-sm) {
    .media-body {
      text-align: right;
    }

    padding-top: 0;
  }
}

.show-hover:not(:hover) .show-hover-item { display: none; }

// Header
.site-header {

  .navbar-toggle {
    // top: 30px;
    border-width: 0;
    margin-right: 5px;
    .icon-bar {
      width: @grid-gutter-width;
      height: 3px;
      background-color: @brand-primary;
    }

    &:hover, &:focus {
      background-color: transparent;
    }
  }
}


.top-header-link {
  display: inline-block;
  padding: 2px @padding-small-vertical;
  position: relative;
  color: @brand-primary;
  &:not(:first-child):after {
    content: '';
    position: absolute;
    left: 0;
    top: 3px;
    height: ~"calc(100% - 6px)";
    width:0;
    border-left: 1px solid;
  }

  span {
    display: block;
    padding: 1px 2px;
    border-radius: 2px;
  }

  &.active span,
  &.active:hover span {
    color: @brand-secondary;
  }

  &:hover {
    text-decoration: none;
    span {
      color: lighten(@brand-secondary, 10%);
    }
  }
}




.navbar-static-top {
  border: 0;
}


@media (max-width: @grid-float-breakpoint) {
  .navbar-wrapper {
    position: absolute;
    z-index: 2;
    left: 15px;
    right: 15px;
  }
}

#primaryNav {
  @media (max-width: @grid-float-breakpoint) {
    .navbar-nav {
      margin-top:1px;
      margin-bottom:0;
      li a {
        border-top: 1px solid @gray-lighter;
        border-bottom: 1px solid @gray-lighter;
        margin-top: -1px;
        + .dropdown-menu {
          padding: 0;
        }
      }

      li:last-of-type a {
        border-bottom: 0;
      }
    }
  }


    @media (min-width: @grid-float-breakpoint) {
      .navbar-nav > li > a {
        padding-left: 8px;
      }
      .navbar-nav > li:not(.dropdown) > a {
        padding-right: 8px;
      }
    }

}


.navbar-nav > li.dropdown {
  > a {
    padding-right: 30px;
    &:after {
      .fa-icon();
      content: @fa-var-chevron-down;
      position: absolute;
      top: ~"calc(50% - 6px)";
      right: 15px;
      font-size: 0.75em;

    }
  }

  .active-parent:not(.item-level-1) > a {
    &,&:hover, &:focus {
      outline: none;
      background-color: @blue-dark;
      color: #ffffff;
    }
  }
}

.navbar-default .navbar-nav > li.active > a,
.navbar-default .navbar-nav > li.active-parent > a {
  &,
  &:hover,
  &:focus {
    color: @blue-light;
  }
}

.navbar-default .navbar-nav > li.active-parent > a {
  &,
  &:hover,
  &:focus {
    background-color: @navbar-default-link-active-bg;
  }
}

.navbar-nav-main {
  @media (min-width: @grid-float-breakpoint) and (max-width: @screen-md) {
    margin-left: -15px;
  }
}

// Top Search

.navbar-form-search {
  padding-top: @grid-gutter-width / 3;
  padding-bottom: @grid-gutter-width / 3;
  position: relative;
  &:extend(.container);
  .transition(margin 0.05s ease-in-out);
}

.navbar-form {
  .form-control {
    border: 0;
    border-bottom: 1px solid @gray-light;
    box-shadow: none;
  }
}

.navbar-search {
  .btn-link:focus {
    outline: none;
  }

  .btn-link {
    float: left;
    margin-bottom: 8px;
    margin-top: 8px;

    @media (min-width: @screen-sm) {
      float: none;
      margin-top: 5px;
      margin-bottom: 0;
      margin-right: -5px;
    }
  }

  @media (max-width: @grid-float-breakpoint) {
    display: none;
  }
}

[data-target="#navSearch"] {
  .nav-open-search {
    display: none;
  }

  &.collapsed {
    .nav-close-search {
      display: none;
    }
    .nav-open-search {
      display: inline-block;
    }
  }
}

.navbar-search-toggle {
  padding: 7px 10px;
  margin-right: 0 !important;
}


// Sharing

.article-sharing {
  min-height: 28px;
  text-align: center;
  float: left;
  @media (min-width: @screen-sm) {
    float: right;
  }
}

.share-btn {
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
  color: @brand-primary;
  font-size: 14px;
}

.share-total {
  display: inline-block;
  background: @gray-light;
  color: #fff;
  padding: 2px 5px;
  position: relative;
  margin-left: 8px;

  &:before {
    content: "";
    display: inline-block;
    border: 5px solid @gray-light;
    border-left: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-top: 5px solid transparent;
    position: absolute;
    left: -10px;
    top: 10px;
  }
}

// Form extend styles

.btn-outline {
  background-color: transparent;
  color: inherit;
}

.input-outline.input-group {
  border: 1px solid @btn-default-border;
  .btn,
  .form-control {
    background: transparent;
    border-width: 0;
    .box-shadow(none);
  }
}


.form .form-action {
  .btn {
    &:extend(.btn-default);
  }
}

.help-block {
  font-weight:200;
}

.form-inline {
  @media (min-width: @screen-sm) {
    .form-group {
      margin-right: 5px;
    }
    .control-label {
      margin-right: 10px;
    }
  }
}

.datewrapper {

  select { &:extend(.form-control); }

  .select {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 5px;
  }
}

.select {
  margin-top: 5px;
  margin-bottom: 5px;
}

.select select {
  display: block;
  position: relative;
  background: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.input-icon {
  position: relative;
  &:after,
  &:before {
    .fa-icon();
    position: absolute;
    z-index:1;
    top: ~'calc(50% - 8px)';
    color: @gray;
  }
  
  &.left { 
    &:before {
      left: @padding-base-horizontal - 2;
    }
    > .dropdown,
    > .form-control,
    > select {
      padding-left: 2.2em;
    }
    
    &-auto:before { left: auto; }
  }
  
  &.right { 
    &:after {
      right: @padding-base-horizontal - 2;
    }
    > .dropdown,
    > .form-control,
    > select {
      padding-right: 2.2em;
    }
    &-auto:after { right: auto; }
  }
  
  > .dropdown,
  > .form-control {
    &:focus, &:active {
      outline: none;
    }
  }
}

.input-icon.search.left:before { content: @fa-var-search; }
.input-icon.search.right:after { content: @fa-var-search; }
.input-icon.search.right:after { content: @fa-var-search; }
.input-icon.select.left:before { content: @fa-var-angle-down; }
.input-icon.select.right:after { content: @fa-var-angle-down; }

[data-selectize] {
  .form-control();
  height: 38px !important;
  overflow: hidden;

  .opacity(0);

  option {
    .opacity(0);
  }
}

.selectize-control.single .selectize-input:after {
  right: 12px;
}


.selectize-control.default {
  min-width: 200px;
}

.selectize-control.single .remove {
  display: none;
}

.selectize-control.half {
  min-width: 130px;
}

.selectize-control.year {
  min-width: 100px;
}

.selectize-control.selectize-phase {
  min-width: 100px;
}

.selectize-input {
  padding-right: 50px;
  border-radius: 0 !important;
  vertical-align: middle;

  [data-value] {
    width: auto;
    line-height: 1.5 !important;
  }

  > input {
    line-height: 1.5 !important;
  }
 &.has-options:not(.input-active) {
   [data-value] {
     margin-right: 4px;
   }
 }
}

.form-group .instructions {
  color: lighten(@text-color, 25%);
  font-size: @font-size-small;
  margin-bottom: 5px;
  *:last-child {
    margin-bottom: 0;
  }
}

.control-label, label {
  + .instructions {
      margin-top: -5px;
  }
}

label.required:after {
  content: ' *';
  color: @brand-danger;
  vertical-align: middle;
  font-size: 0.75em;
}

.pagination {
  margin-top: 0;
}

.thumbnail {
  background-color: transparent;
}

.datepicker-dropdown {
  padding: 5px;
  background-color: #ffffff;
  border-color: @gray-lighter;
}

.datepicker table tr td.active.active,
.datepicker table tr td.active.highlighted.active,
.datepicker table tr td.active.highlighted:active,
.datepicker table tr td.active.highlighted:hover,
.datepicker table tr td.active:active, .datepicker table tr td.active:hover {
  background-color: @green-dark;
}

// Slick extends
[data-slick-carousel-default] {

  &.slick-slider {
    margin-bottom: 0;
  }
  .slick-dots {
    bottom: 10px;
  }
  .slick-prev {
    left: 10px;
    z-index: 1;
  }
  .slick-next {
    right: 10px;
  }

  .slick-slide {
    img {
      margin: 0 auto;
    }
  }
}


.slick-track {
  display: flex;
  .slick-slide {
    display: flex;
    height: auto;
  }
}

.slick-dashes li { 
  width: 34px; height: 20px; cursor: pointer; position: relative; padding: 0;
  &.slick-active .dash { border-color: #38ABDD !important; }
}

// Responsive background image
.image-responsive {
  position: relative;
  background-repeat:no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
}

.image-responsive-8by5 {
  padding-bottom: percentage(5/8);
}


// Table extends
.table-collapse-reverse {
  border-collapse: initial;
  border-spacing: 3px;

  td {
    border-width: 0 !important;
  }

  caption {
    margin: 3px;
    padding: 8px;
    font-size: 18px;
    font-weight: 200;
    text-transform: uppercase;
  }

  &.table-condensed {
    > thead,
    > tbody,
    > tfoot {
      > tr {
        > th,
        > td {
          font-size: @font-size-small * 1.10;
          &:not(.year) {
            font-weight: 200;
          }
        }
      }
    }
  }
}

.table-bg-cells {
  border-width: 0;
  background-color: #fff;
  td {
    background-color: #f1f1f1;
    color: @brand-primary;

  }
  td.alt {
    background-color: #e3e3e3;
  }
}

td.year {
  font-weight: 600;
}

// bg colors



.nav-stacked a[data-toggle="collapse"] {
  border-top: 1px solid @gray-light;
  margin-top: -1px;

  &:not(.collapsed) {
    border-bottom: 1px solid @gray-light;
    &:after {
      content: @fa-var-chevron-up;
    }
  }

  &:after {
    .fa-icon();
    content: @fa-var-chevron-down;
    position: absolute;
    top: ~"calc(50% - 6px)";
    right: 15px;
    font-size: 0.75em;
  }
}

.nav-stacked > li + li {
  margin-top: 1px;
}

.nav-stacked:not(.nav-columns) {
  >li {
  
    > a:hover, a:focus {
      background-color: #fff;
      color: @green-light;
    }
  }

}

.sidebar {
  .nav-stacked:not(.nav-columns) {
    > li {
      &.active, &.active-parent {
        background-color: #fff;
      }
    }
  }

  a[data-toggle="collapse"]:not(.collapsed),
  a[data-toggle="collapse"] {
    border-width: 0;

    &:hover, &:focus {
      background-color: #fff;
    }
  }
  a[data-toggle="collapse"] {
    padding-right: 30px;
    &:hover {
      color: lighten(@green-dark, 5%);
    }
  }

  a[data-toggle="collapse"]:not(.collapsed) {
    color: @green-dark;
  }

  .nav-stacked.nav-columns.nav-active {
    background: #fff;
  }
}


.nav-stacked {

  > li.active-parent > a,
  > li.active > a {
    color: @green-dark;
    font-style: italic;
  }
  .nav-stacked {

    > li > a {
      @media (min-width: @screen-sm) {
        padding-right: 30px;
      }
      
      color: @gray-dark;


      &:hover, &:focus {
        background-color: #fff;
        color: @blue-light;
      }
    }

    > li.active > a {
      color: @blue-light;
      font-weight: 400;
      background-color: #fff;

    }
  }

  .nav-stacked > li.active {
    .nav-stacked {
      background-color: #fff;
    }
  }
}

.member {
  align-items: center;
  &:before {
    content: '';
    display: inline-block;
    width: 1em;
    height: 1em;
    background-image: url('@{img-path}favicon-16x16.png');
    vertical-align: middle;
    margin-right: 3px;
  }

  .heading-readmore {
    margin-left: auto;
  }
}

.listing {


  position: relative;
  .loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: -1px;
    display: none;
    z-index: 1000;
    background-color: rgba(255, 255, 255, 0.5);
    background-image: url('@{img-path}oval.svg');
    background-repeat: no-repeat;
    background-position: 50% 5%;


    @media (min-width: @screen-sm) {
      background-position: 50% 12%;
    }

    &.loading {
      display: block;
    }

  }
}



.listing.documents {
  .meta {
    * {
      display: inline-block;
      &:not(:last-child) {
        margin-right: 2px;
      }
    }
  }
}

.heading-light {
  font-weight: 300 !important;
}

.results {
  font-size: 18px;
  padding: @padding-small-vertical;
  margin-bottom: @font-size-h6;

  @media (min-width: @screen-sm) {
    font-size:  20px;
  }
}

.results-info {
  color: @blue-dark;
}
.tabbed-content {
  margin-top: 30px;
  .panel {
    margin-top: -1px;
  }

  .nav-pills > li > a {
    border: 1px solid transparent;
    border-bottom-width: 0;

  }

  .nav-pills > li:not(:first-child) > a {
      margin-left: -1px;
  }
  .nav-pills > li > a:hover {
    border-color: @panel-default-border;
  }
  .nav-pills > li.active > a,

  .nav-pills > li.active > a:hover,
  .nav-pills > li.active > a:focus {
    border-color: @panel-default-border;
    background-color: @panel-default-heading-bg;

  }
}

blockquote {
  font-style: italic;
  cite {
    font-style: normal !important;
    font-size: @font-size-base - 2;
  }
}
// columns
@media(min-width: @screen-md) {
  .columns.two {
    .column {
      position: relative;
      float: left;
      width: 50%;

      &.one {
        padding-right: 7.5px;

      }
      &.two {
        padding-left: 7.5px;
      }
    }
  }
}
.block {
  position: relative;
  padding-bottom: 1px;
}

// Column modifiers
@media (min-width: @screen-xs) and (max-width: @screen-sm) {
  .col-xs-sm-6 {
    width: percentage(6/12);
    float: left;
  }
}

@media (min-width: @screen-sm) and (max-width: @screen-md) {
  .col-sm-md-6 {
    width: percentage(6/12);
    float: left;
  }
}

@media (min-width: @screen-xs) and (max-width: @screen-md) {
  .col-xs-md-6 {
    width: percentage(6/12);
    float: left;
  }
}

/* spacers */
.m-a-0 { margin: 0; }
.m-t-0,
.mt-0 { margin-top:0; }
.m-b-0 { margin-bottom: 0; }

.m-b-1 { margin-bottom: @grid-gutter-width / 2; }
.p-b-1 { padding-bottom: @grid-gutter-width / 2; }



.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-2 {
  margin-top: @grid-gutter-width;
  margin-bottom: @grid-gutter-width;
}

.p-a-sm-2 {
  padding: @grid-gutter-width / 2 0;
  @media (min-width: @screen-sm) {
    padding: @grid-gutter-width 0;
  }
}


@media (min-width: @screen-sm) {
  .custom-affix.sticky {
    position: fixed;
    top: 20px;
    z-index: 100;
  }

  .custom-affix.static {
    position: relative;
  }
}

#main {
  position: relative;
  min-height: 400px;
}

.selectize-clear {
  padding-left: 0;
  padding-right: 0;
  margin-right: 45px;
  margin-left: -52px;
  position: relative;
  z-index:2;
}

.white-popup {
  position: relative;
  background: #FFF;
  padding: 20px;
  width:auto;
  max-width: 500px;
  margin: 20px auto;
}

.h-50 { height: 50%;}
.h-100 { height: 100%; }

@media (min-width: @screen-sm) {
  .h-sm-100 { height: 100%; }
}

.w-100 {
  width: 100%;
}

.w-xs-50 {
  @media (min-width: @screen-xs) {
    width: 50%;
  }
}
.w-25 {
	width: 25%;
}

.w-50 {
  width: 50%;
}
.w-75 {
  width: 75%;
}
@media (min-width: @screen-sm) {
	.w-sm-25 {
		width: 25%;
	}
	.w-sm-50 {
	  width: 50%;
	}
	.w-sm-75 {
	  width: 75%;
	}
}


.btn-browse {
  position: relative;
  z-index: 1;
}

.fileinput-filename {
  display: none;
  padding: 8px 0;
}

.fileinput-preview.thumbnail {
  padding: 0;
  width: 200px;
}

.fileinput {
  position: relative;
}

.fileinput-preview {
  position: relative;
  display: block;
}

.fileinput-exists .fileinput-new,
.fileinput-new .fileinput-exists,
.fileinput-new .fileinput-remove {
  display: none;
}

.fileinput-exists .fileinput-filename {
  display: inline-block;
}

.fileinput-remove {
  opacity: 0.65;
  color: @gray-light;
  font-size: 14px;
  padding: 5px;

  &:hover {
    color: @brand-danger;
    opacity: 0.85;
  }
}

.close.fileinput-exists {
  float: none;
  font-size: @font-size-large;
  color: @brand-warning;
  opacity: 0.5;
}

.btn-file > input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  font-size: 23px;
  height: 100%;
  width: 100%;
  direction: ltr;
  cursor: pointer;
}
.d-block { display: block; }
.d-ib { display: inline-block;}

.d-flex { display: flex; }
.d-sm-flex { 
  @media (min-width: @screen-sm) {
    display: flex !important;
  }
}
.flex-wrap { flex-wrap: wrap; }
.flex-column { flex-direction: column; }
.justify-content-between { justify-content: space-between; }
.justify-content-end { justify-content: end; }
.justify-content-center { justify-content: center; }
.align-items-center { align-items: center; }
.align-items-end { align-items: flex-end; }

.gap-1 {
	gap: 0.25rem;
}
.gap-2 {
	gap: 0.5rem;
}

.gap-3 {
	gap: 1rem;
}
.gap-4 {
	gap: 1.5rem;
}

ul.ui-addtocal {
  &:extend(.dropdown-menu all);
  > li > a  {
    cursor: pointer;
  }
}

.flex-fill {
  flex: 1 1 auto!important;
}
.flex-shrink-0 {
  flex-shrink: 0 !important;
}
.lh-1 {
  line-height: 1;
}

.position-absolute {
  position: absolute;
}
.position-relative {
  position: relative;
}

.zindex-1 {
  z-index: 1;
}
.zindex-2 {
  z-index: 2;
}




// Covid-19 hub

//#covid19-heading {
//
//}

#covid19-monitor {
  h2 {
    //color: @orange;
    color: @covid-red;
    border-bottom: 1px solid @covid-red;
    //border-bottom: 1px solid #d05300;
  }
  dt {
    color: @orange;
  }
  .list-group-item, .list-group-item.active {
    transition: background-color 100ms;
  }

  .list-group-item.active {
    background: @covid-red;
    border-color: @covid-red;
  }

  .selectize-dropdown {
    z-index: 110!important;
  }
  .selectize-control {
    width: 270px;
  }
}

.covid19-country-heading {
  position: sticky;
  top: 0;
  padding-left: 0%;
  background: #fff;
  z-index: 100;
  padding-bottom: 10px;
  font-weight: 600;
  text-align: center;

  img {
    margin-left: -40px;
  }
}

#floating-nav {
  height: 100%;
  border: 1px solid #ccc;

  overflow-y: scroll;
  border-radius: 3px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background: #fff;
  z-index: -1;
}

.navbar-default .navbar-nav > li.active-parent.nav-item-covid-19 > a,
.nav > li.active.nav-item-covid-19 > a {
  color: #fff;
}

.navbar-default .navbar-nav>li.nav-item-covid-19>a:hover,
.navbar-default .navbar-nav>.open.nav-item-covid-19>a{
  color: #fff;

}

// .nav-item-covid-19 {
//   > a {
//     color: #fff;
//     background: @covid-red !important;
//     &:hover, &:focus {
//       color: #fff;
//     }
//   }
// }

@covid-dark-red: rgb(64, 0, 0);  // Dark re
@covid-red: rgb(179, 7, 1); // Red
@covid-orange: rgb(246, 134, 43);  // Orange
@covid-yellow: rgb(252, 195, 61); // Yellow

.nav-section-covid-19 {
  .page-heading {
    background: @covid-red;
    .page-title {
      color: #fff;
    }
    .breadcrumb a {
      color: #fff;
    }

    //border-bottom: 1px solid darken(@orange, 10%);
  }

  .page-title-label {
    border-color: #fff;
  }
}


.article-slug-covid-19-public-finance-monitor {
  .article-title {
    color: @covid-red;

//    display: flex;
//    //background: @orange;
//    //color: #fff;
//    //margin-top: 0px;
//    //padding-top: 10px;
//    //padding-bottom: 10px;
//
//    padding: 30px;
//    margin-left: -30px;
//    margin-top: -30px;
//
//    span {
//      flex-grow: 1;
//    }
//
//    img {
//      height: 40px;
//    }
//
    &:after {
      content: "";
      display: block;
      background-color: @orange;
      background-image: url('/images/cabri-covid-1.png');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      height: 80px;
      width: 80px;
      right: 0;
      top: -22px;
      position: absolute;
    }
  }
}

.article-heading + .article-body > .section {
  padding-top: 0 !important;
}

.nav-section-covid-19 .fact-simple dt {
  padding-left: 0;
  font-weight: 600;
}

.d-inline-block {
  display: inline-block !important;
}

//

.selectize-dropdown-content .optgroup .optgroup-header {
  font-weight: bold;
}

.selectize-dropdown-content .optgroup .optgroup-header ~ .option {
  padding-left: 20px;
}


@import 'debt-monitor';
@import 'health-survey';

#africa-map {

}

#africa-map-overlay {
  path {
    fill: transparent;
    stroke-width: 1;
    stroke: transparent;
  }

  .svg-map-link {
    cursor: pointer;
    //fill: green;
  }

  .svg-map-link {
    stroke-width: 1;
    stroke: @orange;

    &:hover {
      //stroke-width: 1;
      //stroke: @green-light;
      fill: rgba(255,255,255,0.25)
    }
  }
}

.embed-responsive-4by1 {
  padding-bottom: percentage(1/4);
}

.w-screen {
  width: 100vw;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.mfp-close-white .mfp-close {
  color: #fff;
}

.text-decoration-none {
	text-decoration: none;
}