[ng-cloak] {
  display: none
}

[ng-click] {
  cursor: pointer;
}

#debt-monitor {
  h4, h2 {
    text-transform: uppercase;
  }

  h3 {
    /*border-bottom: 1px solid lightgrey;*/
  }

  //h4 {
  //  margin-bottom: 5px;;
  //}

  .panel-info {
    border-color: #80884A;

    > .panel-heading {
      background: #A8B584;

      color: darken(#80884A, 10%);

      .fa {
        color: #80884A;
        width: 22px;
        text-align: center;
      }
    }

    > .panel-heading + .panel-collapse > .panel-body {
      border-top-color: #80884A;
    }
  }
}


.lg-value {
  font-size: 24px;

}

.lg-value:before {
  font-weight: bold;
}

.lg-stat {
  position: relative;
  font-size: 45px;
  font-weight: bold;
  width: 150px;
  height: 85px;
  //background: #00495e;
  //color: #fff;
  border-radius: 50px;
  line-height: 50px;
  //text-align: center;
}

.lg-stat * {
  display: inline-block;
}

.stat-label {
  position: absolute;
  bottom: 5px;
  left: 0;
  //width: 100%;
  //text-align: center;
  font-weight: normal;

  font-size: 13px;
  line-height: 13px;
}


.frequency-stat {
  background: #00495e;
  color: #fff;
  font-size: 22px;
  display: inline-block;
  padding: 10px 20px;
  margin-bottom: 15px;
  margin-top: 5px;

  &:before {
    content: "@{fa-var-clock-o} ";
    font-family: FontAwesome;
    display: inline-block;
    padding-right: 10px;
  }

  border-radius: 25px;
}


.statement {
  padding: 10px 20px;;
  color: #fff;
  background: grey;
  margin-bottom: 20px;;
  display: inline-block;
  font-size: 22px;
  margin-top: 10px;
}

.statement-good {
  background: darkolivegreen;
  //&:before {
  //  content: "@{fa-var-check} ";
  //  font-family: FontAwesome;
  //  display: inline-block;
  //  padding-right:10px;
  //}
}

.statement-bad {
  background: darkred;

  //&:before {
  //  content: "@{fa-var-times} ";
  //  font-family: FontAwesome;
  //  display: inline-block;
  //  padding-right:10px;
  //}
}

.statement-continuation-heading {
  margin: 0;
  margin-left: 60px;
  padding-left: 10px;
  padding: 10px;
  border-left: 1px solid grey;
  font-size: 16px;
  margin-top: -20px;;
}

.statement-continuation {
  margin-left: 30px;
}

.description {
  margin-left: 5px;
  border-left: 1px solid lightgrey;
  padding-left: 20px;
  margin-bottom: 20px;
}

.fact-heading:before {
  content: "› ";

  .btn {
    //margin-top: -5px;
    padding: 0;
  }
}

.graph-holder {
  border: 1px solid @gray-lighter;
  padding: 10px;
  margin-bottom: 20px;

  h4 {
    margin-top: 0;
    text-align: center;

  }
}

.number-only {
  .currency-value {
    min-height: 100px;
    line-height: 100px;
    text-align: center;
    font-weight: bold;
  }
}

.graph-caption {
  text-align: center;
  color: @brand-primary;
}

.debt-graph {
  h3 {
    font-size: 18px;
    line-height: 1.5;
    border-bottom: 1px solid @brand-primary;
    padding-bottom: 5px;
  }

  //text-align: center;
  min-height: 200px;
  transition: box-shadow 0.5s linear;

  .graph-link {
    display: block;
    text-align: center;
    font-size: 13px;
  }

  .currency-value {
    font-size: 32px;
  }

  .currency-value-date {
    color: @brand-primary;
    font-size: 13px;
  }
}

.debt-table {
  table {
    font-size: 13px;
  }
}

tr.debt-total {
  background: @gray-lighter;
}

.table-sm {
  font-size: 13px;

  td {
    padding: 3px;
  }
}

.debt-total-label {
  .label;
  .label-default;
}

factoid {
  width: 100%;
}

.fact-simple {
  transition: background 0.5s linear;

  .btn.btn-compare {
    opacity: 0;
    transition: opacity 0.5s linear;
    position: absolute;
    top: 5px;
    left: 5px;

  }

  &:hover {
    background: rgba(0, 0, 0, 0.05);

    .btn.btn-compare {
      opacity: 1;
    }
  }

  .clearfix;

  margin-bottom: 0;
  display: table;
  width: 100%;
  position: relative;


  dt {
    display: table-cell;
    //float: left;
    width: 38%;
    padding-right: 2%;
    text-align: right;
    color: @brand-primary;
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    padding-bottom: 8px;
    padding-top: 8px;
    padding-left: 60px;
    margin-left: 0;
    vertical-align: top;
  }

  dd {
    display: table-cell;
    //float: left;
    padding-left: 2%;
    width: 55%;
    padding-bottom: 8px;
    padding-top: 8px;
  }
}

.fact-yes, .fact-no, .fact-na {
  display: inline-block;
  padding: 4px 8px;
  border: 1px solid yellowgreen;
  color: yellowgreen;
  border-radius: 25px;
  margin-left: -8px;
}

.fact-yes {
  border-color: @gray;
  color: @gray;
}

.fact-no {
  border-color: @gray;
  color: @gray;
}

.fact-na {
  border-color: @gray-light;
  color: @gray-light;
}

.fact-table {
  th {
    color: @brand-primary;
  }
}

#debt-survey-accordion {
  .panel-info .panel-default {
    border: none;
    box-shadow: none;

    .panel-body {
      border: none;
    }

    .panel-heading {
      background: none;
      border-bottom: 1px solid @gray-light;

      .panel-title {
        font-weight: bold;
      }
    }

    .fact-table > tbody > tr:first-child > th {
      border-top: 0;
    }
  }
}

.ui-select-match-close {
  position: absolute;
  top: 2px;
  right: 2px;
}

.ui-select-multiple.ui-select-bootstrap .ui-select-match-item {
  padding-right: 15px;
  white-space: normal;
}

.btn.btn-compare {

  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  //border: 1px solid lighten(@brand-primary, 25%);
  border-radius: 10px;
  padding: 2px 8px;
  margin-top: 3px;
  opacity: 0.3;

  display: block;
  text-align: right;
  margin-left: auto;
  //padding-right: 0;

  &:hover {
    opacity: 1;
  }
}

.panel-heading {
  .btn.btn-compare {
    float: right;
    margin-top: 0;
  }
}

#debt-table {
  font-size: 12px;
}

.fact-scale {
  border-bottom: 2px solid @brand-primary;
  position: relative;

  .scale-1, .scale-2, .scale-3, .scale-4 {
    width: 5px;
    height: 5px;
    position: absolute;
    top: 0;
    left: 0%;
  }

}

factoid-scale {
  width: 100%;

  .progress {
    margin-bottom: 5px;
  }
}

.ui-checkbox-tree {
  border: 1px solid @gray-lighter;
  position: relative;
  padding: 3px 3px 3px 18px;
  box-sizing: border-box;
  width: 100%;

  //.ui-checkbox-expand {
  //  position: absolute;
  //}

  //padding: 5px 5px 5px 10px;
  //border: 1px solid @gray-lighter;
  //position: relative;

  .ui-checkbox-button {
    //display: inline-block;
    //width: auto;
    border: none;
    padding-left: 2px;
    margin-bottom: 0;
  }

  span.ui-checkbox-button {
    padding-left: 10px;
  }

  .ui-checkbox-expand {
    position: absolute;
    left: 5px;
    top: 4px;

    .fa {
      font-size: 12px;
    }
  }
}

ui-checkbox-tree, ui-checkbox {

  box-sizing: border-box;
  position: relative;
  font-size: 12px;


  .ui-checkbox-top:not(:empty) {
    margin-bottom: 5px;
    border-bottom: 2px solid @brand-primary;
  }

  .ui-checkbox-button {
    background: none;
    margin-bottom: 5px;
    display: block;
    border: 1px solid @gray-light;
    width: 100%;
    text-align: left;
    border-radius: 3px;
    margin-right: 5px;
    box-sizing: border-box;
  }

  span.ui-checkbox-button {

  }

  button.ui-checkbox-button {

    //i.fa {
    //  width: 18px;
    //}

    &:hover {
      background: @gray-lighter;
    }

    &:active, &:focus {
      outline: none;
    }

    &.ui-checkbox-checked {
      color: @brand-primary;
      border-color: @brand-primary;
    }

    &[disabled] {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }

  .ui-checkbox-scrollable {
    width: 100%;
    min-height: 200px;
    max-height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
    padding: 5px;
  }


}

ui-checkbox-tree {
  .ui-checkbox-scrollable {
    height: 260px;
    min-height: 260px;
    max-height: 260px;
  }
}

.nav-tabs-right {
  li {
    float: right;
  }
}

.form-control-sm {
  font-size: 13px;
  height: 28px;
}

.nav-tabs-sm {
  font-size: 13px;

  &.nav > li > a {
    padding: 6px 10px;
  }
}

debt-term {
  border-bottom: 1px dotted lightblue;
  cursor: pointer;
  display: inline-block;

  div {
    display: inline-block;
  }
}

.popover[uib-popover-popup] {
  font-size: 13px;

  .popover-title {
    font-size: 13px;
    font-weight: bold;
  }
}

